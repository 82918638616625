.types_section {
  display: flex;
  flex-direction: column;
  gap: 35px;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  transition: transform 0.3s ease-in-out;
  height: 100%;
  @media only screen and (max-width: 900px) {
    margin: 0 10px;
    flex-direction: row;
    gap: 12px;
  }
}
.types_section::-webkit-scrollbar {
  display: none !important; /* Webkit (Safari, Chrome) */
}
