.footer {
  background: #eaf4ee;
  width: 100%;
  padding: 16px 170px 24px 170px;
  display: flex;
  //   justify-content: flex-start;
  align-items: flex-start;
  justify-content: space-between;
  gap: 80px;
  @media only screen and (max-width: 1589px) {
    padding: 16px 70px;
    gap: 60px;
  }
  @media only screen and (max-width: 1091px) {
    padding: 16px 30px;
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 700px) {
    padding: 16px 20px;
    gap: 40px;
    align-items: center;
    justify-content: center;
  }
  .s1 {
    width: 80%;
    margin-top: 10px;
    @media only screen and (max-width: 700px) {
      text-align: center;
    }
    .items_s1 {
      margin-top: 10px;

      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      white-space: nowrap;
      flex-direction: column;
      gap: 10px;
      @media only screen and (max-width: 700px) {
        align-items: center;
      }
      span {
        display: flex;
        gap: 10px;
      }
    }
  }
  .s2 {
    width: 80%;
    margin-top: 10px;
    @media only screen and (max-width: 700px) {
      text-align: center;
    }
  }
  .s3 {
    width: 80%;
    margin-top: 10px;
    @media only screen and (max-width: 700px) {
      text-align: center;
    }
  }
  .left {
    width: 120%;
    max-width: 280px;
    @media only screen and (max-width: 700px) {
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 45px; /* white */
      color: #ffffff;
      margin-bottom: 14px;
    }
    .content {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #1b1c57;
    }
  }
  .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;
    max-width: 253px;
    @media only screen and (max-width: 700px) {
      align-items: center;
      justify-content: center;
    }

    .contact_sub {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      text-transform: capitalize;
      color: #1b1c57;
      @media only screen and (max-width: 700px) {
        text-align: center;
      }
    }
    .icons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
    }
  }
  .nav_footer {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    text-transform: capitalize;
    color: #0e1735;
    margin-bottom: 5px;
  }
  .contact_sub {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-transform: capitalize;
    color: #1b1c57;
  }
}

.bottom_section {
  height: 33px;
  background: #1b1c57;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 400;
  font-size: 10.5px;
  line-height: 18px;
  color: #ffffff;
  gap: 6px;
  padding: 7px 0;
}
