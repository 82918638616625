.circlar_progress_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  .ant-spin {
    span {
      font-size: 50px !important;
      color: #04c23a !important;
    }
  }
}
