@use "../abstracts/variables" as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  // overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-width: 4px;
  scrollbar-color: #dadada #f4f4f4;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
  z-index: 120;
}

*::-webkit-scrollbar-track {
  background: #f4f4f4;
}

*::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 0;
  border: 0px solid #f4f4f4;
}

.light {
  .text {
    color: $dark;
  }

  .background {
    background-color: $white;
  }
}

.dark {
  .text {
    color: $white;
  }

  .background {
    background-color: $dark;
  }
}

.full_screen_action.arabic {
  right: 40px !important;
}

.full_screen_action.english {
  left: 40px !important;
}

.media_page.full-screen {
  .full_screen_action.arabic {
    right: -140px !important;
    top: -70px;

    @media only screen and (max-width: 1024px) {
      right: -145px !important;
      top: -45px;
    }

    @media only screen and (max-width: 820px) {
      right: 20px !important;
      top: -165px;
    }

    @media only screen and (max-width: 768px) {
      right: 20px !important;
      top: -165px;
    }

    @media only screen and (max-width: 460px) {
      top: -150px;
    }

    @media only screen and (max-width: 414px) {
      top: -135px;
    }
  }

  .full_screen_action.english {
    left: -140px !important;
    top: -70px;

    @media only screen and (max-width: 1024px) {
      left: -145px !important;
      top: -45px;
    }

    @media only screen and (max-width: 820px) {
      left: 20px !important;
      top: -165px;
    }

    @media only screen and (max-width: 768px) {
      left: 20px !important;
      top: -165px;
    }

    @media only screen and (max-width: 460px) {
      top: -150px;
    }

    @media only screen and (max-width: 414px) {
      top: -135px;
    }
  }
}


[dir=rtl] .slick-slide {
  float: unset !important;
}

.media_page.full-screen .slick-slider {
  background-color: rgba(0, 0, 0, 0.78);
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100VW;
  z-index: 1000;

  .slick-dots {
    display: none !important;
  }

  .slick-list .slick-track {
    gap: 0
  }

  .slick-list .slick-track .slick-slide .item_slide {
    border-radius: 0 !important;

    iframe {
      border-radius: 0 !important;
    }

    img {
      border-radius: 0px !important;
    }
  }

}